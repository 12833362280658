<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id + '/product'}">展品管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加展品</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="pageRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="归属参展商" prop="exhibitor">
          <el-select v-model="pageForm.exhibitor" filterable placeholder="请选择">
            <el-option v-for="item in exhibitorList" :key="item.id" :label="item.title" :value="item.m_id">{{item.title}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="展品标题" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入展品标题"></el-input>
        </el-form-item>
        <el-form-item label="展品缩略图" prop="thumbnail">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="展品价格" prop="price">
          <el-input type="number" v-model="pageForm.price" placeholder="请输入展品价格（元）"></el-input>
        </el-form-item>
        <el-form-item label="展品滚动图" prop="banner">
          <upload :urlArr="pageForm.banner" :filed="'banner'" :max-num="5" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="展品描述" prop="desc">
          <QuillBar v-model="pageForm.desc" :contenttext="pageForm.desc" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item label="购买链接" prop="buy_link">
          <el-input v-model="pageForm.buy_link" placeholder="请输入第三方购买链接"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添 加</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      exhibitionTitle: '',
      pageForm: {
        exhibition: this.$route.params.m_id,
        sort: 0,
        exhibitor: '',
        title: '',
        thumbnail: '',
        price: 0,
        banner: [],
        desc: '',
        buy_link: ''
      },
      pageRules: {
        exhibitor: [
          { required: true, message: '请选择归归属参展商', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写展品标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传展品缩略图', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入展品价格', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请上传展品滚动图', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入展品描述', trigger: 'blur' }
        ],
        buy_link: [
          { required: true, message: '请输入购买链接', trigger: 'blur' }
        ]
      },
      // 参展商列表
      exhibitorList: []
    }
  },
  created () {
    this.getExhibitionTitle()
    this.getExhibitorList()
  },
  methods: {
    // 获取参展商列表
    async getExhibitorList () {
      const { data: res } = await this.$http.get('/exhibition-exhibitor-list', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.exhibitorList = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    onSubmit () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/exhibition-product', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/product')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    cancel () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/product')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    getExhibitionTitle () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    itemChange (val) {
      this.pageForm.desc = val
    }
  }
}
</script>

<style scoped>
/deep/.el-radio{
  margin-bottom: 14px;
}
.page-content{
  width: 800px;
  margin-right: auto;
  margin-left: auto;
}
</style>
